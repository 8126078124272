import React from "react";
import { Image, Button } from "@components/anti";

const NotFoundPage = () => {
  return (
    <section className="sc-notfound py-main">
      <div className="content container">
        <div className="img-wrapper">
          <Image
            src="/img/new/img_new-ico-404.png"
            width="391"
            height="231"
            className="img-fluid"
          />
        </div>
        <div className="mt-2">
          <p className="title text-center">
            Uh oh, you’ve wandered beyond our kitchen
          </p>
          <p className="description text-center">
            The page you are looking for is not available. Please go back to
            home.
          </p>
          <div className="d-flex justify-content-center mt-4">
            <Button className="btn-back" variant="link">
              Back to Home
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
